<template>
	<div class="realtimeauditing">

		<el-card class="box-card mb10">
			<el-form :model="queryinfo" class="queryinfo" label-suffix="：">
				<el-row :gutter="24">
					<el-col :span="6">
						<el-form-item label="开始日期">
							<el-date-picker
							 v-model="queryinfo.start"
							 ormat="yyyy-MM-dd hh:mm:ss"
							 placeholder="选择日期时间"
							 type="datetime"
							 value-format="yyyy-MM-dd hh:mm:ss"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="结束日期">
							<el-date-picker
							 v-model="queryinfo.end"
							 ormat="yyyy-MM-dd hh:mm:ss"
							 placeholder="选择日期时间"
							 type="datetime"
							 value-format="yyyy-MM-dd hh:mm:ss"
							/>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="微站Id">
							<el-select
							 v-model="queryinfo.weiId"
							 placeholder="请选择"
							 style="width: 80%"
							 @change="getCodes">
								<el-option label="QA232200000874" value="QA232200000874"/>
								<el-option label="QA232200000875" value="QA232200000875"/>
								<el-option label="QA232200000872" value="QA232200000872"/>
								<el-option label="QA232200000873" value="QA232200000873"/>
								<el-option label="QA232200000874" value="QA232200000874"/>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="类型">
							<el-select
							 v-model="queryinfo.type"
							 placeholder="请选择"
							 style="width: 80%">
								<el-option label="小时数据" value="1"/>
								<el-option label="日数据" value="2"/>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="检测因子">
							<el-select
							 v-model="queryinfo.codes"
							 multiple
							 placeholder="请选择"
							 style="width: 80%"
							 @change="getCodes">
								<el-option
								 v-for="item in codesList"
								 :key="item.value"
								 :label="item.label"
								 :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div class="searchorrestbutton">
					<el-button class="searchbutton" icon="el-icon-search" size="small" type="primary" @click="getWeiZhanDataList">
						搜索
					</el-button>
					<el-button class="resetbutton" icon="el-icon-refresh-left" size="small">重置</el-button>

				</div>
			</el-form>
		</el-card>
		<el-card class="box-card">
			<div class="mb10">
			</div>
			<!-- 搜索与添加区域 -->
			<div id="realtimeauditing" style="width:100%; height: 4.625rem"></div>
		</el-card>
	</div>
</template>
<script>
import {getWeiZhanDataList} from "@/utils/masterdata.js";
import echartMixins from "@/utils/resizeMixins";

export default {
	data() {
		return {
			chart: null,
			blueMax: [],
			wzAvg: [],
			diff: [],
			line: 0,
			category: [
				"0时",
				"",
				"2时",
				"",
				"4时",
				"",
				"6时",
				"",
				"8时",
				"",
				"10时",
				"",
				"12时",
				"",
				"14时",
				"",
				"16时",
				"",
				"18时",
				"",
				"20时",
				"",
				"22时",
				"",
			],
			axisLine: {
				lineStyle: {
					color: "#093E83",
				},
			},
			echartsData:{},
			axisLabel: {
				textStyle: {
					color: "white",
				},
			},
			id: "",
			a01001Avg:[],
			codesList: [
				{label: "温度（℃）", value: "a01001Avg"},
				{label: "湿度（%）", value: "a01002Avg"},
				{label: "气压（HPA）", value: "a01006Avg"},
				{label: "风速（M/S）", value: "a01007Avg"},
				{label: "风向（°）", value: "a01008Avg"},
				{label: "臭氧（ug/m3）", value: "a05024Avg"},
				{label: "二氧化氮（ug/m3）", value: "a21004Avg"},
				{label: "voc（ug/m3）", value: "a24088Avg"},
				{label: "pm10（ug/m3）", value: "a34002Avg"},
				{label: "pm2.5（ug/m3）", value: "a34004Avg"},

			],
			centerDialogVisible: false,
			inputstates: false,
			addobtntate: "添加",
			queryinfo: {
				index: 1,
				pageSize: 10,
				type: "1",
				codes: ["a01001Avg", "a01002Avg", "a01006Avg", "a01007Avg", "a01008Avg",
					"a05024Avg", "a21004Avg", "a24088Avg", "a34002Avg", "a34004Avg",
				],
				weiId: "QA232200000874"
			},
			userList: [],
			total: 0,
			tableData: [],
			timeList:[],
		};
	},
	mixins: [echartMixins],
	mounted() {
		console.log(this.getStartTime()+ " 00:00:00")
		this.$set(this.queryinfo,'start',this.getStartTime()+ " 00:00:00")

		this.$set(this.queryinfo,'end',this.getEndTime()+ " 00:00:00")
		this.getWeiZhanDataList()

		this.$forceUpdate()
	},
	methods: {
		exportFile(){

		},
		getCodes(e) {
			console.log(e)
		},
		draw() {
			// 基于准备好的q，初始化echarts实例
			this.chart = this.$echarts.init(
			 document.getElementById("realtimeauditing")
			);
			let option = {
				backgroundColor: 'white',
				grid: {
					top: '20%',
					left: '5%',
					right: '5%',
					bottom: '8%',
					containLabel: true,
				},
				legend: {
					show:true,
					left: "center",
					top: 30,
					itemWidth: 15,
					itemHeight: 10,
					itemGap: 15,
					borderRadius: 4,
					textStyle: {
						color: "#000",
						fontFamily: "Alibaba PuHuiTi",
						fontSize: 14,
						fontWeight: 400,
					},
				},
				xAxis: {
					type: 'category',
					data: this.timeList,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: true,
						textStyle: {
							color: '#393939', //X轴文字颜色
						},
					},
				},
				yAxis: [

					{
						type: 'value',
						name: '主导产业变迁',
						nameTextStyle: {
							color: "#000",
							fontFamily: "Alibaba PuHuiTi",
							fontSize: 14,
							fontWeight: 600,
							// padding: [0, 0, 0, 40], // 四个数字分别为上右下左与原位置距离
						},
						nameGap: 30,  // 表现为上下位置
						axisLine: {
							show: true,
							lineStyle: {
								color: '#eeeeee',
							}
						},
						axisTick: {
							show: true
						},
						axisLabel: {
							color: '#393939',
							fontSize: 14
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: '#eeeeee',
							}
						},

					},

				],
				series: [
					{
						name: '温度',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a01001Avg,
					},
					{
						name: '湿度',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a01002Avg,
					},
					{
						name: '气压',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a01006Avg,
					},
					{
						name: '风速',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a01007Avg,

					},
					{
						name: '风向',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a01008Avg,

					},
					{
						name: '臭氧',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a05024Avg,
					},
					{
						name: '二氧化氮',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a21004Avg,
					},
					{
						name: 'voc',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a24088Avg,
					},
					{
						name: 'pm10',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a34002Avg,
					},
					{
						name: 'pm2.5',
						type: 'line',
						// showAllSymbol: true, //显示所有图形。
						symbol: 'circle', //标记的图形为实心圆
						symbolSize: 8, //标记的大小
						itemStyle: {
							//折线拐点标志的样式
							color: 'white',
							borderWidth: '2',
							borderColor: '#5470c6',
							normal: {
								color: "#5470c6",
							},
						},

						lineStyle: {
							color: '#5470c6',
						},

						data: this.echartsData.a34004Avg,

					},
				],
			};


			this.chart.setOption(option);
			window.onresize = this.chart.resize;
		},
		getEndTime() {
			let date = new Date()
			let year = date.getFullYear()
			let month = date.getMonth() + 1
			// 这里传入的是整数时间，返回的是下个月的第一天，因为月份是0-11
			let nextMonthFirthDay = new Date(year, month, 1) // 下个月的第一天
			let oneDay = 1000 * 60 * 60 * 24 // 一天的时间毫秒数
			let endDay = new Date(nextMonthFirthDay - oneDay)
			let day = endDay.getDate() // 本月最后一天

			// 这里传入的是字符串格式的时间，返回的是传入字符串的时间
			return year + '-' + month + '-' + day
		},

		getStartTime() {
			let date = new Date()
			date.setDate(1) // 将当前时间的日期设置成第一天
			let year = date.getFullYear()  // 得到当前年份
			let month = date.getMonth() + 1 // 得到当前月份（0-11月份，+1是当前月份）
			month = month > 10 ? month : '0' + month // 补零
			let day = date.getDate() // 得到当前天数，实际是本月第一天，因为前面setDate(1) 设置过了
			return year + '-' + month + '-' + day// 这里传入的是字符串
		},
		async getWeiZhanDataList() {
			let queryinfo = {
				...this.queryinfo,
				end: this.queryinfo.end ,
				start: this.queryinfo.start,
				codes: this.queryinfo.codes.join(',')
			}
			const {body} = await getWeiZhanDataList({...queryinfo});
			this.tableData = body
			// this.echartsData=
			for (const obj in body[0]) {
				if(obj!='dataTimeStr'){
					this.echartsData[obj]=body.map((e)=>{
						return e[obj]
					})
				}else{
					this.timeList=body.map((e)=>{
						return e[obj]
					})
				}
			}
			this.draw()
			// this.total = body.count
		},

	},
};
</script>
<style lang="scss">
.realtimeauditing {
	.el-form-item__content {
		width: 100%;

	}
}

</style>
